<template>
  <v-container>
    <v-row justify="space-around">
      <v-col cols="12">
        <v-card class="card">
          <v-list-item two-line>
            <v-list-item-content>
                <div class="overline mb-4">
                    <h3>Oops</h3>
                </div>
                <v-list-item-title class="headline mb-1">
                  Something went Wrong
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


export default {
    name: 'Error',
}

</script>


<style scoped>

</style>